const state = {
  mouseEnabled: true,
  touchEnabled: true,
  globalToolSyncEnabled: false,
  showSVGCursors: false,
};

export default {
  state,
  setters: {},
};
